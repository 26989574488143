import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const AssetWealthManagement = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="AssetWealthManagement"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">The Asset and Wealth Management industry is facing massive disruption – with dramatic changes in investor attitudes, behavior, and expectations, along with a growing demand for digitally enabled services. There's also an expectation of lower fees, pricing transparency, and a greater willingness to switch providers if investor needs aren't met.</p>
                        <p className="mt-3">TEQT BPS offers solutions to address these challenges, backed by 20+ years of domain experience in Asset & Wealth Management. This expertise is combined with data, analytics, digital tools, accelerators, frameworks, and platforms. These solutions for the front, middle, and back-office have enabled clients to achieve up to 70% cost efficiency and enhanced compliance. Our client base includes 2 Fortune 100 Global Banks, 5 of the top banks in the U.S., and a global provider of investment processing, operations, and asset management solutions that manages, advises on, or administers over $1 trillion in assets.</p>
                        <h6 className="mt-3">2500+ Professionals</h6>
                        <h6 className="mt-3">50,000+ Advisors Supported</h6>
                        <h6 className="mt-3">20 U.S. & Global Institutions</h6>
                        <h6 className="mt-3">2 of Top 5 U.S. Broker Dealers</h6>
                        <h6 className="mt-3">Top Analyst Recommendations</h6>
                        <h6 className="mt-3">40+ Business Transformations</h6>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AssetWealthManagementPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AssetWealthManagement.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AssetWealthManagement;
